<template>
  <div style="border: 1px solid; display: inline-block;">
    <div style="display: grid; grid-template-columns: max-content auto;">
      <template  v-for="(i, v) in tableData" :key="v"  >
        <div  class="px-2" style="width: 100px; border: 1px solid; display:flex; align-items:center; justify-content:center">
          {{ i.name }}
        </div>
        <div >
          <div
            v-for="(v, index) in i.values"
            :key="index"
            style="display: flex;"
          >
            <div style="display: flex; flex:1">
              <div style="width: 8em; border: 1px solid; padding: 5px; text-align: left;">
                {{ v.name }}
              </div>
              <div style="flex:1; border: 1px solid; padding: 5px 20px; text-align:left;">
                {{ v.value }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
    name: 'RenderOSQRTable',
    props: {
        tableData: {
            type: Object,
            default: () => ({}),
        }
    }
}
</script>

<style scoped>

</style>
